import { Fragment } from "react";

import {
  formatNumber,
  getDamageLevel,
  getHighestDamage,
  getRepairType,
  thumbColor,
} from "../../utils/helper";
import { DAMAGE_LEVEL_MN } from "../../utils/constant";
import Typography from "../../base-components/Typography";
import Card from "../Card";
import SliderLevel from "../../base-components/Slider";
import useStore2 from "../../store/hook";

const Invoice = () => {
  const estimations = useStore2.useEstimations();
  const zuragu = useStore2.useZuragu();
  let totalAmount = 0;
  const renderInvoices = () => {
    const zuraguDatas: any[] = [];
    zuragu.forEach((zur) => {
      zur.data.forEach((da) => {
        if (estimations !== undefined) {
          const estimation = estimations.find(
            (estimation) => estimation.class_name === da.part_class
          );
          zuraguDatas.push({
            part_class: da.part_class,
            part_index: da.part_index,
            damage_class: da.damage_class,
            iou_sum: da.iou_sum,
            image_base64: da.image_base64,
            spare_parts_price: estimation?.spare_parts_price || 0,
            repair_price: estimation?.repair_price || 0,
            material_price: estimation?.material_price || 0,
            labor_fee: estimation?.labor_fee || 0,
          });
        }
      });
    });
    return zuraguDatas.map((part, idx) => {
      const sum =
        (part.spare_parts_price || 0) +
        (part.repair_price || 0) +
        (part.material_price || 0) +
        (part.labor_fee || 0);
      totalAmount += sum;
      return (
        <Card key={`invoice-${idx}`} size="sm" className="p-3">
          <div className="flex items-center space-x-4">
            <img
              src={
                part.image_base64
                  ? `data:image/png;base64,${part.image_base64}`
                  : "/images/damage.png"
              }
              alt="vahicle-damage"
              className="h-10 w-10 object-cover rounded"
            />
            <div>
              <Typography size="sm" className="text-[#2D1F52A3]">
                Гэмтэл - {idx + 1}
              </Typography>
              <Typography variant="Label">
                {part.part_class.charAt(0).toUpperCase() +
                  part.part_class.slice(1)}
              </Typography>
            </div>
          </div>
          <Card size="xs" className="bg-gray">
            <div className="flex items-center space-x-2">
              <Typography size="sm" className="text-black-64">
                Эвдрэлийн зэрэг:
              </Typography>
              <Typography
                variant="Title"
                size="sm"
                className={thumbColor(getDamageLevel(part.damage_class))}
              >
                {DAMAGE_LEVEL_MN[getDamageLevel(part.damage_class)]}
              </Typography>
            </div>
            <SliderLevel level={getDamageLevel(part.damage_class)} />
            <div className="grid grid-cols-2 my-2">
              <div>
                <Typography size="sm" className="text-black-64">
                  Эвдрэлийн төрөл:
                </Typography>
                <Typography variant="Title" size="sm">
                  {getHighestDamage(part.damage_class).charAt(0).toUpperCase() +
                    getHighestDamage(part.damage_class).slice(1).toLowerCase()}
                </Typography>
              </div>
              <div>
                <Typography size="sm" className="text-black-64">
                  Засах/Солих:
                </Typography>
                <Typography variant="Title" size="sm">
                  {getRepairType(part.damage_class)}
                </Typography>
              </div>
            </div>
          </Card>
          <div className="space-y-1 pb-3 border-b border-dark-12">
            <div className="flex justify-between">
              <Typography size="sm" className="text-[#2D1F52A3]">
                Сэлбэгийн үнэ
              </Typography>
              <Typography size="sm" className="text-black-64">
                {formatNumber(part.spare_parts_price)}₮
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography size="sm" className="text-[#2D1F52A3]">
                Засварын зардал
              </Typography>
              <Typography size="sm" className="text-black-64">
                {formatNumber(part.repair_price)}₮
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography size="sm" className="text-[#2D1F52A3]">
                Материал үнэ
              </Typography>
              <Typography size="sm" className="text-black-64">
                {formatNumber(part.material_price)}₮
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography size="sm" className="text-[#2D1F52A3]">
                Ажлын хөлс
              </Typography>
              <Typography size="sm" className="text-black-64">
                {formatNumber(part.labor_fee)}₮
              </Typography>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <Typography size="sm" className="text-[#2D1F52A3]">
              Нийт үнэ
            </Typography>
            <Typography variant="Label">{formatNumber(sum)}₮</Typography>
          </div>
        </Card>
      );
    });
  };

  return (
    <Fragment>
      <Typography variant="Title" size="sm" className="text-black-64">
        Эвдрэлийн хэсэг
      </Typography>
      <div className="my-4 space-y-3">{renderInvoices()}</div>
      <div className="-mx-4 border-t border-dark-12 p-4">
        <div className="w-full flex justify-between items-center">
          <Typography variant="Title" size="md" className="text-black-64">
            Нийт төлөх дүн
          </Typography>
          <Typography variant="Headline" size="md" className="text-[#20163B]">
            {formatNumber(totalAmount)}₮
          </Typography>
        </div>
      </div>
    </Fragment>
  );
};

export default Invoice;
