import Service from "./BaseService";

const save = (payload, config) =>
  Service.create("/odoo/object-info", payload, config);

const saveXyp = (payload, config) =>
  Service.create("/odoo/xyp-object-info", payload, config);

const getObjectFields = (id, config) =>
  Service.get(
    `/dynamicInsurance/get-object-fields?dynamic_insurance_product_id=${id}`,
    { ...config, nonLoading: true }
  );

const ObjectInfoService = {
  save,
  saveXyp,
  getObjectFields,
};

export default ObjectInfoService;
