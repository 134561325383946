import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { useStore } from "../../store";
import { stringValue } from "../../utils/helper";
import VinService from "../../services/VinService";
import TakePhoto from "../../components/TakePhoto";
import Instructions from "../../components/Instructions";
import Typography from "../../base-components/Typography";
import Button from "../../base-components/Button";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";

const Vin = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const image = useStore.useImage();
  const setImage = useStore.useSetImage();
  const setPage = useStore.useSetPage();
  const imageChanged = useStore.useImageChanged();
  const setImageChanged = useStore.useSetImageChanged();
  const tokenData = useStore.useTokenData();
  const setTokenData = useStore.useSetTokenData();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!params.mode) {
      VinService.get("vin").then((response) => {
        setImage(response.data?.vin);
        setTokenData(response.token);
        setImageChanged(false);
      });
    }
  }, [params.mode, setImage, setImageChanged, setTokenData]);

  function saveVin() {
    if (imageChanged) {
      const payload = { vin: image };
      VinService.save(payload).then(() => {
        navigate("/damages");
      });
    } else {
      navigate("/damages");
    }
  }

  const handleTakePhoto = () => {
    setPage(location.pathname);
    navigate("/camera");
  };

  return (
    <>
      <div className="space-y-6">
        <Typography variant="Title" size="md" className="w-56">
          Арлын дугаарын зургыг авч баталгаажуулна уу?
        </Typography>
        <div className="bg-white border border-dark-12 rounded-2xl p-4 space-y-4">
          <div className="py-3 border-b border-dark-12">
            <Typography size="sm" type="primary-soft">
              Арлын дугаар:
            </Typography>
            <Typography variant="Title" size="sm">
              {stringValue(tokenData?.vin)}
            </Typography>
          </div>
          {image ? (
            <div className="border border-secondary-12 rounded-lg h-[169px] flex justify-center items-center">
              <img src={image} alt="vin" className="h-full" />
            </div>
          ) : (
            <TakePhoto
              className="h-[169px]"
              onTakePhoto={() => handleTakePhoto()}
            />
          )}
          <Button
            variant="primary-outlined"
            onClick={() => {
              setIsOpen(true);
            }}
            className="w-full mt-4"
            icon={<Question />}
          >
            Арлын дугаар хаана байрладаг вэ?
          </Button>
        </div>
        {image && (
          <div className="absolute bottom-4 inset-x-0 px-4">
            <Button
              onClick={() => saveVin()}
              icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
              className="w-full"
            >
              Баталгаажуулах
            </Button>
          </div>
        )}
      </div>
      <Instructions open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Vin;
