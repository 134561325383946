import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useStore, useStoreBase } from "../../store";
import Typography from "../../base-components/Typography";
import useStore2 from "../../store/hook";
import EstimationService from "../../services/estimation/EstimationService";
import { getHighestDamage } from "../../utils/helper";
import { iParts } from "../../services/estimation/EstimationService";
import OdooEstimationService from "../../services/OdooEstimationService";

const Calculations = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [wait, setWait] = useState(false);

  const setToken = useStore.useSetToken();
  const clearLoading = useStore.useClearLoading();
  const setDamagedParts = useStore2.useSetDamagedParts();
  const setTokenData = useStore.useSetTokenData();
  const vehicleInfo = useStore2.useVehicleInfo();
  const victim = useStore2.useVictim();
  // const damagedParts = useStore2.useDamagedParts();
  const zuragu = useStore2.useZuragu();
  const setEstimations = useStore2.useSetEstimations();
  const name = useStore2.useName();

  useEffect(() => {
    // get first 4 digits of importDate
    const importDate = vehicleInfo?.importDate?.slice(0, 4);
    const parts: iParts[] = [];
    zuragu.forEach((zur) => {
      zur.data.forEach((da) => {
        parts.push({
          className: da.part_class,
          typeOfBreakdown: getHighestDamage(da.damage_class),
        });
      });
    });
    // zuragu.map((zur) => {
    //   return zur.data.map((da) => {
    //     return ;
    //   })

    // })

    const payload = {
      markName: vehicleInfo?.make || "",
      modelName: vehicleInfo?.model || "",
      buildYear: vehicleInfo?.year.toString() || "",
      importYear: importDate || "",
      cabinNumber: vehicleInfo?.vin || "",
      plateNumber: vehicleInfo?.plateNumber || "",
      phone: victim?.mobile || "",
      firstName: victim?.name || "",
      register: victim?.registryNumber || "",
      parts: parts,
    };

    const startedTime = Date.now();
    const delayTime = Math.random() * 1000 + 3000;
    const toFinishTime = startedTime + delayTime;

    const checkTime = () => {
      if (Date.now() >= toFinishTime) {
        clearLoading(false)
        navigate("/invoice");
      } else {
        setTimeout(checkTime, 100);
      }
    };

    EstimationService.CalculatePrice(payload)
      .then((response) => {
        if (response.status === false) {
          useStoreBase.setState({
            notification: {
              message: response.message,
              type: "warning",
            },
          });
        } else {
          console.log(response.data);
          setEstimations(response.data);
          // save estimation data to aisys
          OdooEstimationService.save({ name: name, data: response.data }).finally(()=>{
            checkTime();
          });
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    // DamageService.calcPrice({}).then((response) => {
    //   if (!!response.is_wait) {
    //     setWait(true);
    //   } else {
    //     setDamagedParts(response.data);
    //     setTokenData(response.token);
    //     navigate("/invoice");
    //   }
    // });
  }, [
    navigate,
    zuragu,
    vehicleInfo?.importDate,
    vehicleInfo?.make,
    vehicleInfo?.model,
    vehicleInfo?.plateNumber,
    vehicleInfo?.vin,
    vehicleInfo?.year,
    victim?.mobile,
    victim?.name,
    victim?.registryNumber,
    setEstimations,
  ]);

  return (
    <div className="flex justify-center h-screen">
      <div className="h-full">
        <div className="relative h-2/3 flex justify-center items-center">
          {/* <img src="/images/load.png" alt="vehicle-pic" className="blink" /> */}
          <img
            src="/images/load.png"
            alt="load"
            color="#B3C0D0"
            className="absolute z-10"
          />
        </div>
        <div className="h-1/3">
          <Typography className="text-center p-8">
            {wait
              ? "Таны хохирлын үнэлгээг тооцоход 1-2 цаг болно."
              : "Хохирлын үнэлгээ тооцоолж байна. Та түр хүлээнэ үү..."}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Calculations;
