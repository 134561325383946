import { useEffect } from "react";
import Typography from "../../base-components/Typography";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";
import DamageService from "../../services/DamageService";
import useStore2 from "../../store/hook";

const Progress = () => {
  const navigate = useNavigate();

  // const setDamagedParts = useStore2.useSetDamagedParts();
  const setZuragu = useStore2.useSetZuragu();
  const setTokenData = useStore.useSetTokenData();
  const createDate = useStore2.useCreateDate();
  const vehicleInfo = useStore2.useVehicleInfo();
  const victim = useStore2.useVictim();
  const name = useStore2.useName();

  useEffect(() => {
    const startedTime = Date.now();
    const delayTime = Math.random() * 2000 + 4000;
    const toFinishTime = startedTime + delayTime;

    const checkTime = () => {
      if (Date.now() >= toFinishTime) {
        navigate("/result");
      } else {
        setTimeout(checkTime, 100);
      }
    };

    DamageService.submitDamage(name, victim)
      .then((response) => {
        console.log(response);
        // setDamagedParts(response.data);
        setZuragu(response.data);
        setTokenData(response.token);
        checkTime();
      })
      .catch((e) => {
        console.log(e);
        navigate("/damages");
      });
  }, [
    createDate,
    name,
    navigate,
    // setDamagedParts,
    setZuragu,
    setTokenData,
    vehicleInfo?.plateNumber,
  ]);

  return (
    <div className="flex justify-center h-screen">
      <div className="h-full">
        <div className="relative h-2/3 flex justify-center items-center">
          <img src="/sample-pics/simple.png" alt="vehicle-pic" />
          <div className="scan-progress"></div>
        </div>
        <div className="h-1/3">
          <Typography className="text-center p-8">
            Эвдрэл танилт хийгдэж байна. Та түр хүлээнэ үү...
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Progress;
