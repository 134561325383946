import { StateCreator } from "zustand";

interface iZuraguData {
  part_class: string;
  part_index: number;
  damage_class: string[];
  iou_sum: number[];
  image_base64: string;
}

interface iZuraguResponse {
  response_time: number;
  data: iZuraguData[];
}

export interface iZuragu {
  zuragu: iZuraguResponse[];
  setZuragu: (zuragu: iZuraguResponse[]) => void;
}

const createZuraguSlice: StateCreator<iZuragu> = (set) => ({
  zuragu: [],
  setZuragu: (zuragu) => set(() => ({ zuragu: zuragu })),
});
export default createZuraguSlice;
