import Service from "./BaseService";
import { iCalculatePriceResponseData } from "./estimation/EstimationService";

const save = (payload: { name: string; data: iCalculatePriceResponseData[] }) =>
  Service.create(`/odoo/estimation`, payload);

const FourSidesService = {
  save,
};

export default FourSidesService;
