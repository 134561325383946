import { DAMAGE_LEVEL } from "./constant";

export const isNullOrUndefined = (value: any) => {
  return typeof value === "undefined" || value === null;
};

export function isEmptyObject(params: any) {
  return typeof params === "object" && Object.keys(params).length === 0;
}

export const formatNumber = (value: any, digit = 2) => {
  if (value === undefined) {
    return "0.00";
  }
  let number = value;
  if (typeof value === "string") number = value.replace(/,/g, "");
  if (isNaN(Number(number))) {
    let text = number.slice(0, number.length - 1);
    return Number(text).toLocaleString(undefined, {
      minimumFractionDigits: digit,
    });
  }
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: digit,
  });
};

export const parseId = (odoo_id: any) => {
  if (!Array.isArray(odoo_id)) return null;
  if (odoo_id.length !== 2) return null;
  return parseInt(odoo_id[0]);
};

export const parseValue = (odoo_id: any) => {
  if (!Array.isArray(odoo_id)) return null;
  if (odoo_id.length !== 2) return null;
  return odoo_id[1];
};

export const stringValue = (str: any) => {
  if (!str) return "";
  return str;
};

export const thumbColor = (level: any) => {
  switch (level) {
    case DAMAGE_LEVEL.LOW:
      return "text-[#06994D]";
    case DAMAGE_LEVEL.MIDDLE:
      return "text-[#EEC079]";
    case DAMAGE_LEVEL.HIGH:
      return "text-[#E7645A]";
    default:
      return "text-[#06994D]";
  }
};
export const formatDateToYYMMDDHHMMSS = (timezone: string) => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

// format date from xyp to YYYY-MM-DD
// example: "datetime.datetime(2021,2,22,0,0, tzinfo=<FixedOffset '+08:00'>)" => "2021-02-22"
// example 2: "2021-02-22T00:00:00+08:00" => "2021-02-22"
export const formatXypDate = (xypDate: string) => {
  console.log(xypDate);
  if (xypDate.split("-")[0].length === 4) return xypDate.split("T")[0];
  const date = xypDate.split("(")[1].split(")")[0];
  const [year, month, day] = date.split(",");
  // if month and/or day is less than 10, add 0 in front of it
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const getDamageLevel = (damages: string[]) => {
  // "зурагдсан", "хонхойсон","хагарсан"
  if (damages.includes("хагарсан")) return "high";
  if (damages.includes("хонхойсон")) return "middle";
  return "low";
};

export const getRepairType = (damages: string[]) => {
  if (damages.includes("хагарсан")) return "Солих";
  return "Засах";
};

export const getHighestDamage = (damages: string[]) => {
  // "гэрлийн шил эвдэрсэн" => "хагарсан"
  // "дугуй хагарсан" => "хагарсан"
  // "шил хагарсан" => "хагарсан"
  // "хагарсан" => "хагарсан"
  // "зурагдсан" => "зурагдсан"
  // "хонхойсон" => "хонхойсон"
  if (
    damages.includes("хагарсан") ||
    damages.includes("гэрлийн шил эвдэрсэн") ||
    damages.includes("дугуй хагарсан") ||
    damages.includes("шил хагарсан")
  )
    return "хагарсан";
  else if (damages.includes("хонхойсон")) return "хонхойсон";
  else if (damages.includes("зурагдсан")) return "зурагдсан";
  return "хагарсан";
};
