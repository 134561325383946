import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import useStore2 from "../../store/hook";
import { formatXypDate, stringValue } from "../../utils/helper";
import Button from "../../base-components/Button";
import Input from "../../base-components/Input";
import Typography from "../../base-components/Typography";
import Card from "../../components/Card";
import Tile from "../../components/Tile";
import { ReactComponent as Check } from "../../assets/icons/check-circle-2-1.svg";
import { ReactComponent as Car } from "../../assets/icons/car-1.svg";
import { ReactComponent as SadRobot } from "../../assets/icons/sad-robot.svg";
import XypService from "../../services/xyp/service";
import EstimationService from "../../services/estimation/EstimationService";

interface XypVehicleInfoProps {
  plate_number: string;
}

const XypVehicleInfo = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      plate_number: "",
      vin: "",
    },
  });

  const [estimationAvailable, setEstimationAvailable] = useState(true);
  const vehicleInfo = useStore2.useVehicleInfo();
  const setVehicleInfo = useStore2.useSetVehicleInfo();
  const setName = useStore2.useSetName();

  // Hur-s tatah
  const onSubmitXyp = (data: XypVehicleInfoProps) => {
    console.log(data);
    if (data.plate_number.length === 7) {
      XypService.getVehicle(data.plate_number)
        .then((xypResp) => {
          if (xypResp?.resultCode === 0) {
            // amjilttai tatval unelgee hiilgeh bolomjtoi mashin esehiig shalgana
            EstimationService.CheckData({
              buildYear: xypResp?.response?.buildYear,
              modelName: xypResp?.response?.modelName,
              markName: xypResp?.response?.markName,
              vin: xypResp?.response?.cabinNumber,
            }).then((response) => {
              if (response.status === false) {
                setEstimationAvailable(false);
              } else {
                setEstimationAvailable(true);
                setVehicleInfo({
                  ...xypResp?.response,
                  importDate: formatXypDate(xypResp?.response?.importDate),
                  vin: xypResp?.response?.cabinNumber,
                  make: xypResp?.response?.markName,
                  model: xypResp?.response?.modelName,
                  color: xypResp?.response?.colorName,
                  year: xypResp?.response?.buildYear,
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onFinish = () => {
    if (!!vehicleInfo?.plateNumber) {
      console.log(vehicleInfo.plateNumber);
      setName(vehicleInfo.plateNumber);
      navigate("/damages");
    } else {
      alert("Улсын дугаар оруулаагүй байна!");
    }
  };

  const saveXypInfo = () => {
    onFinish();
  };

  return (
    <Fragment>
      <Typography variant="Title" size="md" className="w-72">
        Хохирогчийн тээврийн хэрэгсэлийн мэдээллийг оруулна уу?
      </Typography>
      <Card>
        <form onSubmit={handleSubmit(onSubmitXyp)} className={"space-y-4"}>
          <div>
            <Input
              label="Улсын дугаар"
              className="w-full"
              disabled={!!watch("vin", "")}
              {...register("plate_number", {
                required: !watch("vin", ""),
              })}
            />
            {errors?.plate_number?.type === "required" && (
              <p className="text-xs text-error font-semibold mt-1 ml-1">
                Улсын дугаараа оруулна уу.
              </p>
            )}
            {errors?.plate_number?.type === "pattern" && (
              <p className="text-error text-xs pl-2 mt-1">
                Улсын дугаар буруу байна.
              </p>
            )}
          </div>
          <Button
            className="w-full"
            disabled={
              (!watch("plate_number", "") && !watch("vin", "")) ||
              estimationAvailable === false
            }
          >
            ХУР-аас татах
          </Button>
        </form>
      </Card>

      {estimationAvailable === false && (
        <div className="flex flex-col items-center gap-4">
          {/* no data sorry */}
          <SadRobot />
          <Typography className="text-center" variant="Body">
            Уучлаарай, энэ удаад би таны машин дээр үнэлгээ хийж чадсангүй,
            дараагийн удаа би улам хөгжих болно оо.
          </Typography>
        </div>
      )}

      {vehicleInfo && (
        <Fragment>
          <Card>
            <header className="flex items-center space-x-3">
              <div className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-secondary-12">
                <Car fill="#E3A13A" className="w-4 h-4" />
              </div>
              <Typography variant="Title" size="md">
                Тээврийн хэрэгслийн мэдээлэл
              </Typography>
            </header>
            <div className="py-2">
              <Tile
                field={"Арлын дугаар"}
                value={stringValue(vehicleInfo?.vin)}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 gap-y-2">
              <Tile
                field={"Улсын дугаар"}
                value={stringValue(vehicleInfo?.plateNumber)}
              />
              <Tile
                field={"Үйлдвэрлэгч"}
                value={stringValue(vehicleInfo?.make)}
              />
              <Tile field={"Модел"} value={stringValue(vehicleInfo?.model)} />
              <Tile field={"Өнгө"} value={stringValue(vehicleInfo?.color)} />
              <Tile
                field={"Үйлдвэрлэсэн он"}
                value={stringValue(vehicleInfo?.year)}
                className="border-none"
              />
              <Tile
                field={"Орж ирсэн огноо"}
                value={stringValue(vehicleInfo?.importDate)}
                className="border-none"
              />
            </div>
          </Card>
          <div className="mt-4 flex space-x-4 py-4">
            <Button
              icon={<Check fill="#fff" className="w-[18px] h-[18px]" />}
              onClick={() => saveXypInfo()}
              className="w-full"
            >
              Баталгаажуулах
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default XypVehicleInfo;
