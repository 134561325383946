import { useNavigate } from "react-router";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ShieldCheck } from "../../assets/icons/shield-check-1.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-down.svg";

// import { useStore } from "../../store";
import {
  getDamageLevel,
  getHighestDamage,
  getRepairType,
  stringValue,
  thumbColor,
} from "../../utils/helper";
import { DAMAGE_LEVEL_MN } from "../../utils/constant";
import Typography from "../../base-components/Typography";
import IconButton from "../../base-components/IconButton";
import SliderLevel from "../../base-components/Slider";
import MuiAccordion, {
  MuiAccordionDetails,
  MuiAccordionHeader,
} from "../../base-components/Accordion";
import Button from "../../base-components/Button";
import Card from "../../components/Card";
import useStore2 from "../../store/hook";
// import EstimationService from "../../services/estimation/EstimationService";
// import { useStoreBase } from "../../store";

const Result = () => {
  const navigate = useNavigate();

  // const damagedParts = useStore2.useDamagedParts();
  const zuragu = useStore2.useZuragu();
  const vehicleInfo = useStore2.useVehicleInfo();
  // const victim = useStore2.useVictim();
  // const tokenData = useStore.useTokenData();

  // [{
  //     "response_time": 1.66,
  //     "data": [{
  //         "part_class": "кафод",
  //         "part_index": 2,
  //         "damage_class": ["хонхойсон"],
  //         "iou_sum": [0.49937],
  //         "image_base64": "/9j/4AAQSkZJ"
  //     }]
  // },{
  //     "response_time": 1.66,
  //     "data": [{
  //         "part_class": "кафод",
  //         "part_index": 2,
  //         "damage_class": ["хонхойсон"],
  //         "iou_sum": [0.49937],
  //         "image_base64": "/9j/4AAQSkZJ"
  //     }]
  // }]

  const renderDamages = () => {
    let index = 0;
    if (zuragu.length > 0) {
      return zuragu.map((zur, idx) => {
        if (zur.data.length > 0) {
          return zur.data.map((da, data_index) => {
            index += 1;
            return (
              <MuiAccordion key={`damaged-part-${idx}-${data_index}`}>
                <MuiAccordionHeader
                  expandIcon={
                    <IconButton size="sm" variant="primary-soft">
                      <Chevron className="h-[18px] w-[18px]" />
                    </IconButton>
                  }
                >
                  <div className="flex items-center space-x-3">
                    <div className="inline-flex justify-center items-center h-10 w-10 rounded-full bg-secondary-12">
                      <ShieldCheck fill="#E3A13A" />
                    </div>
                    <div className="grow">
                      <Typography variant="Title" size="sm">
                        Гэмтэл - {index}
                      </Typography>
                      <Typography size="md" className="text-black-64">
                        {stringValue(
                          da.part_class.charAt(0).toUpperCase() +
                            da.part_class.slice(1).toLowerCase()
                        )}
                      </Typography>
                    </div>
                  </div>
                </MuiAccordionHeader>
                <MuiAccordionDetails>
                  <div className="rounded-lg mb-3 flex justify-center">
                    <img
                      src={
                        da.image_base64
                          ? `data:image/png;base64,${da.image_base64}`
                          : "/images/damage.png"
                      }
                      alt="damage"
                    />
                  </div>
                  <div className="border border-dark-12 rounded-lg bg-white py-2.5 px-4">
                    <div className="flex items-center space-x-2">
                      <Typography size="sm" className="text-black-64">
                        Эвдрэлийн зэрэг:
                      </Typography>
                      <Typography
                        variant="Title"
                        size="sm"
                        className={thumbColor(getDamageLevel(da.damage_class))}
                      >
                        {DAMAGE_LEVEL_MN[getDamageLevel(da.damage_class)]}
                      </Typography>
                    </div>
                    <SliderLevel level={getDamageLevel(da.damage_class)} />
                    <div className="grid grid-cols-2 my-2">
                      <div>
                        <Typography size="sm" className="text-black-64">
                          Эвдрэлийн төрөл:
                        </Typography>
                        <Typography variant="Title" size="sm">
                          {getHighestDamage(da.damage_class)
                            .charAt(0)
                            .toUpperCase() +
                            getHighestDamage(da.damage_class)
                              .slice(1)
                              .toLowerCase()}
                        </Typography>
                      </div>
                      <div>
                        <Typography size="sm" className="text-black-64">
                          Засах/Солих:
                        </Typography>
                        <Typography variant="Title" size="sm">
                          {getRepairType(da.damage_class)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </MuiAccordionDetails>
              </MuiAccordion>
            );
          });
        } else {
          return "";
        }
      });
    } else {
      return;
    }
  };

  return (
    <div>
      <header className="flex items-center space-x-4">
        <Arrow
          className="h-[18px] w-[18px] cursor-pointer"
          onClick={() => navigate("/damages")}
        />
        <Typography variant="Title" size="md">
          Үнэлгээ хийгдэх зураг
        </Typography>
      </header>
      <Card>
        <section className="rounded-lg bg-primary relative flex justify-between py-4 px-3">
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Улсын дугаар:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {/* {stringValue(tokenData?.plate_number)} */}
              {vehicleInfo?.plateNumber}
            </Typography>
          </div>
          <div>
            <Typography size="sm" className="text-white/[64%]">
              Машины үйлдвэр, Марк:
            </Typography>
            <Typography variant="Title" size="sm" className="text-white">
              {vehicleInfo?.make + " " + vehicleInfo?.model}
            </Typography>
          </div>
        </section>
        <section className="my-2 space-y-2 divide-y divide-dark-12">
          {renderDamages()}
        </section>
      </Card>
      <Button
        className="w-full"
        onClick={() => {
          navigate("/calculations");
        }}
      >
        Үнэлгээ хийлгэх
      </Button>
    </div>
  );
};

export default Result;
